import { Box, Button, Stack, Typography } from "@mui/material";
import NavBar from "../../app/views/layout/NavBar";
import building from "../../landingPage/static/assets/imgs/building_instances_crop_low3.jpeg";

const Vanguard = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                width: "100vw",
                boxSizing: "border-box",
            }}
        >
            <NavBar />
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 100%), url(${building})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid rgba(255, 255, 255, 0.05)",
                        borderRadius: 2,
                        backdropFilter: "blur(7px)",
                        isolation: "isolate",
                        transform: "translateZ(0)",
                        width: 600,
                        padding: 5,
                    }}
                >
                    <Stack spacing={5} alignItems="center">
                        <Typography
                            variant="h2"
                            sx={{ color: "white", textAlign: "center" }}
                        >
                            Rovisen Vanguard Program
                        </Typography>
                        <Typography variant="h6" sx={{ color: "white" }}>
                            Próximamente podrás vivir la nueva forma de hacer
                            análisis geoespacial. Inscríbete en el siguiente
                            enlace para acceder anticipadamente a nuestras
                            herramientas de teledetección avanzada.
                        </Typography>
                        <Button
                            onClick={() =>
                                window.location.replace(
                                    "https://www.linkedin.com/showcase/rovisen-vanguard-program/"
                                )
                            }
                            variant="contained"
                            size="large"
                            sx={{
                                backgroundColor: "#473066",
                                color: "white",
                                '&:hover': {
                                    backgroundColor: "#7464a1"
                                }
                            }}
                        >
                            Quiero ser parte
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};

export default Vanguard;
