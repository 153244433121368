//@ts-nocheck
import { useEffect } from 'react';
import rovisen_logo from '../../../landingPage/static/assets/icons/rovisen.png';
import "../../../landingPage/static/css/pages.css";

function NavBar() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const navbarToggler = document.querySelector('.navbar-toggler');
        const responsiveNavItems = document.querySelectorAll('#navbarResponsive .nav-link');

        responsiveNavItems.forEach(responsiveNavItem => {
            responsiveNavItem.addEventListener('click', () => {
                if (navbarToggler && window.getComputedStyle(navbarToggler).display !== 'none') {
                    navbarToggler.click();
                }
            });
        });
    }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-light navbar-shrink" id="mainNav">
            <div className="container px-4 px-lg-5">
                <a className="navbar-brand" href="/#page-top">
                    <img src={rovisen_logo} alt="Logo" width="32" height="32" className="d-inline-block" />
                    Inicio
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive" aria-expanded="false" aria-controls="navbarResponsive"
                    aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item"><a className="nav-link" href="/#technologies">Tecnologías</a></li>
                        <li className="nav-item"><a className="nav-link" href="/#solutions">Soluciones</a></li>
                        <li className="nav-item"><a className="nav-link" href="/#news">Mapa base</a></li>
                        <li className="nav-item"><a className="nav-link" href="/#us">Nosotros</a></li>
                        <li className="nav-item"><a className="nav-link" href="/#signup">Contacto</a></li>
                        <li className="nav-item"><a className="nav-link" href="/news-letter/latest">Newsletter</a></li>
                        <li className="nav-item"><a className="nav-link" href="/vanguard">Vanguard</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
